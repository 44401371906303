import React, { useEffect, useState } from "react";
import {
  Button,
  Spinner
} from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import { DataStore } from "aws-amplify";
import { Presentation, PresentationVote } from "../../models";

import './Presenter.css';
import PresentationVideo from "./PresentationVideo";
import End from "./End";
import WarmupQuestion from "./WarmupQuestion";
import { usePresentationId } from "../../dataStoreFilters";

function newResponse() {
  return {
    id: crypto.randomUUID(),
    text: 'New Response'
  }
}

export default function Presenter({playlist})  {
  const { presentationId } = useParams();
  const [presentation, setPresentation] = useState(null);
  const [allVotes, setAllVotes] = useState({});
  const navigate = useNavigate();
  usePresentationId(presentationId)

  useEffect(() => {
    const subscription = DataStore.observeQuery(Presentation, p => p.id.eq(presentationId)).subscribe(snapshot => {
      const { items, isSynced } = snapshot;
      if(!isSynced) {
        return;
      }
      setPresentation(items[0])
    });

    const votesSubscription = DataStore.observeQuery(PresentationVote, p => p.presentation_id.eq(presentationId)).subscribe(snapshot => {
      const { items, isSynced } = snapshot;
      if(!isSynced) {
        return;
      }

      const votes = {}
      items.forEach(v => {
        votes[v.warmup ? 'warmup' : v.playlist_submission_id] ||= []
        votes[v.warmup ? 'warmup' : v.playlist_submission_id].push(v)
      })
      setAllVotes(votes)
    })

    return () => {
      subscription.unsubscribe();
      votesSubscription.unsubscribe();
    }
  }, [presentationId]);
  
  if(!presentation) {
    return <div>
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>;
  }

  const currentVotes = (presentation.current_submission_id ? allVotes[presentation.current_submission_id] : allVotes.warmup) || []
  const allVideos = playlist.videos.sort((a, b) => a.id.localeCompare(b.id))
  const remainingVideos = allVideos.slice(allVideos.findIndex((v) => v.id === presentation.current_submission_id) + 1)

  return (
    <div className="presentation">
      <div className="headingBanner">
        {playlist.background_image ? <img src={require('../../images/' + playlist.background_image)} className="headingImage" /> : null}
        {window.location.host}/{presentation.slug}
        <Button onClick={() => DataStore.save(Presentation.copyOf(presentation, updated => {
          updated.current_submission_id = null
          updated.polling_submission_id = null
        }))}>Reset</Button>
       </div>

      {presentation.current_submission_id === 'results' ? <End playlist={playlist} votes={allVotes} presentation={presentation} /> :
        presentation.current_submission_id ? <PresentationVideo
          video={playlist.videos.find((v) => v.id === presentation.current_submission_id)}
          remainingVideos={remainingVideos}
          videoCount={allVideos.length}
          votes={currentVotes}
          slackChannel={presentation.slack_channel_name}
          playlist={playlist}
          advanceVideo={() => DataStore.save(Presentation.copyOf(presentation, updated => {
            if(remainingVideos.length === 0) {
              updated.current_submission_id = 'results'
              updated.polling_submission_id = 'results'
            } else {
              updated.current_submission_id = remainingVideos[0].id
            }
          }))}
          setPollingVideo={() => DataStore.save(Presentation.copyOf(presentation, updated => {
            updated.polling_submission_id = presentation.current_submission_id
          }))} /> : <WarmupQuestion
            presentation={presentation}
            playlist={playlist}
            votes={currentVotes}
            startPresentation={() => DataStore.save(Presentation.copyOf(presentation, updated => {
              if(remainingVideos.length !== 0) {
                updated.current_submission_id = remainingVideos[0].id
                updated.polling_submission_id = remainingVideos[0].id
              }
            }))}
            url={`${window.location.protocol}://${window.location.host}/${presentation.slug}`}
          />}

    </div>
  );
}
