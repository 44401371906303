import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";
import PlaylistList from "./components/PlaylistList";
import Playlist from "./components/Playlist";
import Video from "./components/Video";
import PlaylistEditor from "./components/PlaylistEditor";
import SubmitVideo from "./components/SubmitVideo";
import { Spinner } from "react-bootstrap";

import "./App.css";
import { DataStore } from "aws-amplify";
import * as models from "./models";
import CreatePresentation from "./components/CreatePresentation";
import Presenter from "./components/present/Presenter";
import Viewer from "./components/present/Viewer";
import { usePlaylistId } from "./dataStoreFilters";

function RoutedSingleVideo({playlist}) {
  const { id } = useParams();
  const video = playlist.videos.find(
    v => v.arc_embed_id === id
  );
  return <Video {...{ playlist, video, index: -1 }} />;
}

function RoutedVideo({playlist}) {
  const { index } = useParams();
  const id = parseInt(index);
  const video = playlist.videos[id];
  return <Video {...{ playlist, video, index: id }} />;
}

function PlaylistRoutes() {
  const { playlistId } = useParams();
  usePlaylistId(playlistId)
  const [plainPlaylist, setPlaylist] = useState(null);
  const [videos, setVideos] = useState(null);
  
  useEffect(() => {
    const playlistSubscription = DataStore.observeQuery(models.Playlist, p => p.id.eq(playlistId)).subscribe(snapshot => {
      const { items, isSynced } = snapshot;
      if(!isSynced) {
        return;
      }
      setPlaylist(items[0])
    });
    const submissionSubscription = DataStore.observeQuery(models.PlaylistSubmission, ps => ps.playlist_id.eq(playlistId)).subscribe(snapshot => {
      const { items, isSynced } = snapshot;
      if(!isSynced) {
        return;
      }
      setVideos(items)
    });

    () => {
      playlistSubscription.unsubscribe();
      submissionSubscription.unsubscribe();
    }
  }, []);
  
  if(!plainPlaylist || !videos) {
    return <div>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  }

  const playlist = Object.assign({}, plainPlaylist, { videos });

  return <div>
    <Routes>
      <Route path="submit" element={<SubmitVideo playlist={playlist} />} />

      <Route path="present" element={<CreatePresentation playlist={playlist} />} />

      <Route path="present/:presentationId" element={<Presenter playlist={playlist} />} />

      <Route path="edit/:submissionId" element={<SubmitVideo playlist={playlist} />} />

      <Route
        path="single/:id"
        element={<RoutedSingleVideo playlist={playlist} />}
      />


      <Route
        path="edit"
        element={<PlaylistEditor playlist={playlist} />}
      />

      <Route
        path=":index"
        element={<RoutedVideo playlist={playlist} />}
      />

      <Route index
        element={<Playlist playlist={playlist} />}
      />
    </Routes>
  </div>
}

function App() {
  return (
      <Router>
        <div>
          <Routes>
            <Route
              path="/"
              element={<PlaylistList />}
            />

            <Route
              path="/:slug"
              element={<Viewer />}
            />

            <Route
              path="/playlists/create"
              element={<PlaylistEditor />}
            />

            <Route path="/playlist/:playlistId/*" element={<PlaylistRoutes/>} />
          </Routes>
        </div>
      </Router>
  );
}


export default App;
