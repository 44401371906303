import { DataStore } from "aws-amplify";
import { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"
import { Playlist } from "../models";

export default function ({playlist, setReload}) {
  const navigate = useNavigate();
  const [editedPlaylist, setEditedPlaylist] = useState(playlist || {});
  useEffect(() => {
    setEditedPlaylist(playlist || {});
  }, [playlist]);

  async function deletePlaylist() {
    if(confirm(`Are you sure you want to delete '${playlist.name}'?`)) {
      const original = await DataStore.query(Playlist, playlist.id)
      await DataStore.delete(original)
      navigate('/');
    }
  }

  async function submitForm(e) {
    e.preventDefault();
    let res;
    if(playlist) {
      const original = await DataStore.query(Playlist, playlist.id)
      res = await DataStore.save(Playlist.copyOf(original, updated => Object.assign(updated, editedPlaylist)))
    } else {
      res = await DataStore.save(new Playlist(editedPlaylist))
    }
     
    navigate(`/playlist/${res.id}`);
  }

  return <div className="container">
    <h1>
      {playlist ? `Edit playlist: ${playlist.name}` : 'Create playlist'}
    </h1>

    <Form>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control value={editedPlaylist.name || undefined} onChange={(e) => setEditedPlaylist(Object.assign({}, editedPlaylist, { name: e.target.value }))}  />
      </Form.Group>
      <Form.Group>
        <Form.Label>Date</Form.Label>
        <Form.Control value={editedPlaylist.date || undefined} onChange={(e) => setEditedPlaylist(Object.assign({}, editedPlaylist, { date: e.target.value }))}  />
      </Form.Group>
      <Form.Group>
        <Form.Label>Background Image</Form.Label>
        <Form.Control value={editedPlaylist.background_image || undefined} onChange={(e) => setEditedPlaylist(Object.assign({}, editedPlaylist, { background_image: e.target.value }))}  />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Locked" checked={editedPlaylist.locked || false} onChange={(e) => setEditedPlaylist(Object.assign({}, editedPlaylist, { locked: e.target.checked }))} />
        <Form.Text className="text-muted">
          The playlist should be locked a few minutes before video viewing
        </Form.Text>
      </Form.Group>

      <div className="d-flex justify-content-between">
        <Button variant="primary" type="submit" onClick={submitForm}>
          Submit
        </Button>

        {playlist ? <Button variant="danger" onClick={() => deletePlaylist()}>
          Delete
        </Button> : null}
      </div>
    </Form>
  </div>
}