import React, { useEffect, useState } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";

export default function End({playlist, presentation, votes}) {
  const [revealed, setRevealed] = useState(0);

  function WinnersCell({index, winners}) {
    if (revealed > index) {
      return winners;
    }

    if (revealed === index) {
      return (
        <Button variant="secondary" onClick={() => setRevealed((was) => was + 1)}>
          Click to show
        </Button>
      );
    }

    return null;
  }

  const winners = {};
  presentation.categories.forEach((c) => {
    const voteCounts = []
    for(const videoId in votes) {
      if(videoId === 'warmup') {
        continue
      }
      voteCounts.push([videoId, votes[videoId].filter((v) => v.response_id === c.id).length])
    }
    const maxVal = Math.max(...(voteCounts.map(vc => vc[1])), 1)
    winners[c.id] = {
      winningScore: maxVal,
      videos: voteCounts.filter((vc) => vc[1] === maxVal).map((vc) => vc[0])
    }
  })

  function videoForId(id) {
    return playlist.videos.find((v) => v.id === id);
  }

  function winnersForCategory(id) {
    if(!winners[id] || winners[id].videos.length < 1) {
      return null;
    }

    const winnersDiv = winners[id].videos.map(rw => {
      const video = videoForId(rw);
      return (
        <span key={id + "-" + rw}>
          <b>{video.title}</b>{" "}
          <span style={{ color: "gray" }}>{video.people.join(", ")}</span>
          <br />
        </span>
      );
    });

    return (
      <div key={id}>
        <span style={{ color: "red" }}>{winners[id].winningScore}</span> {winnersDiv}
      </div>
    );
  }

  return (
    <div>
      <Container className="results" style={{ marginTop: "120px" }}>
        <Row>
          <Col md={12}>
            <h1 style={{ textAlign: "center", paddingBottom: "20px" }}>
              Top Vote Recipients
            </h1>
          </Col>
        </Row>
        {presentation.categories.map((cat, idx) => <Row className="result" key={cat.id}>
          <Col className="response" md={4}>
            {cat.text}
          </Col>
          <Col className="videos" md={8}>
            <WinnersCell index={idx} winners={winnersForCategory(cat.id)} />
          </Col>
        </Row>)}
      </Container>
    </div>
  );
}
