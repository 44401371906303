import React, { useEffect, useState } from "react";
import {
  Form,
  Spinner
} from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import { DataStore } from "aws-amplify";
import { PlaylistSubmission, Presentation, Playlist, PresentationVote } from "../../models";
import dancingPanda from '../../images/dancing-happy-dance.gif';

import './Viewer.css';
import { CheckCircle, CheckCircleFill } from "react-bootstrap-icons";
import { useAuthenticator } from "@aws-amplify/ui-react";
import PresentationVideo from "./PresentationVideo";
import { usePlaylistId, usePresentationId, usePresentationSlug } from "../../dataStoreFilters";

export default function Viewer({})  {
  const { slug } = useParams();
  const { user } = useAuthenticator((context) => [context.user]);
  const [presentation, setPresentation] = useState(null);
  const [playlist, setPlaylist] = useState(null);
  const [playlistVideos, setPlaylistVideos] = useState(null);
  const [myVotes, setMyVotes] = useState(null);
  const [allVotes, setAllVotes] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  usePresentationSlug(slug)
  usePresentationId(presentation?.id)
  usePlaylistId(presentation?.playlist_id)

  useEffect(() => {
    const presentationSubscription = DataStore.observeQuery(Presentation, p => p.slug.eq(slug)).subscribe(snapshot => {
      const { items, isSynced } = snapshot;
      if(!isSynced) {
        return;
      }
      setPresentation(items[0] || false)
    });

    return () => {
      presentationSubscription.unsubscribe();
    }
  }, [slug]);

  useEffect(() => {
    if(!presentation) {
      return;
    }

    const playlistSubscription = DataStore.observeQuery(Playlist, p => p.id.eq(presentation.playlist_id)).subscribe(snapshot => {
      const { items, isSynced } = snapshot;
      if(!isSynced) {
        return;
      }
      setPlaylist(items[0])
    });
    const submissionSubscription = DataStore.observeQuery(PlaylistSubmission, ps => ps.playlist_id.eq(presentation.playlist_id)).subscribe(snapshot => {
      const { items, isSynced } = snapshot;
      if(!isSynced) {
        return;
      }
      setPlaylistVideos(items)
    });

    const votesSubscription = DataStore.observeQuery(PresentationVote, p => p.presentation_id.eq(presentation.id)).subscribe(snapshot => {
      const { items, isSynced } = snapshot;
      if(!isSynced) {
        return;
      }

      const votes = {}
      items.forEach(v => {
        votes[v.warmup ? 'warmup' : v.playlist_submission_id] ||= []
        votes[v.warmup ? 'warmup' : v.playlist_submission_id].push(v)
      })
      setAllVotes(votes)
    })

    async function loadMyVotes() {
      const myExistingVotes = await DataStore.query(PresentationVote, p => p.presentation_id.eq(presentation.id) && p.owner.eq(user.username))
      const myVotes = {}
      myExistingVotes.forEach(v => {
        myVotes[v.warmup ? 'warmup' : v.playlist_submission_id] ||= []
        myVotes[v.warmup ? 'warmup' : v.playlist_submission_id].push(v)
      })
      setMyVotes(myVotes)
    }
    loadMyVotes()

    return () => {
      playlistSubscription.unsubscribe();
      submissionSubscription.unsubscribe();
      votesSubscription.unsubscribe();
    }
  }, [presentation && presentation.id]);
  
  if(presentation === false) {
    return <div className="viewer">
      <div className="headingBanner">
        Not found
      </div>
      <h4>Could not find presentation '{slug}'</h4>
    </div>
  }

  if(!presentation || !playlist || !playlistVideos || !myVotes) {
    return <div>
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>;
  }

  async function castVote(voteKey, selection) {
    const currentVotes = myVotes[voteKey] || [];
    const votesToRemove = currentVotes.filter((v) => voteKey === 'warmup' ? v.response_id !== selection : v.response_id === selection);
    if(votesToRemove.length > 0) {
      setMyVotes((old) => Object.assign({}, old, { [voteKey]: old[voteKey].filter(v => voteKey === 'warmup' ? v.response_id === selection : v.response_id !== selection) }))
      await Promise.all(votesToRemove.map((vote) => DataStore.delete(vote)))
    }
    if(!currentVotes.some((v) => v.response_id === selection)) {
      const newVote = new PresentationVote({
        presentation_id: presentation.id,
        playlist_submission_id: voteKey !== 'warmup' ? voteKey : null,
        warmup: voteKey === 'warmup',
        response_id: selection
      })
      setMyVotes((old) => Object.assign({}, old, { [voteKey]: [...(old[voteKey] ||[]), newVote] }))
      await DataStore.save(newVote)
    }
    
  }

  const targetId = showVideo ? presentation.current_submission_id : presentation.polling_submission_id
  const selectedVideo = playlistVideos.find((v) => v.id === targetId)
  const prompt = selectedVideo ? `${selectedVideo.title} by ${selectedVideo.people.join(', ')}` : presentation.warmup_question.prompt
  const options = targetId ? presentation.categories : presentation.warmup_question.options
  const voteKey = targetId ? targetId : 'warmup'
  const currentVotes = allVotes && allVotes[targetId] || []

  return (
    <div className="viewer">
      <div className="headingBanner">
        {playlist.background_image ? <img src={require('../../images/' + playlist.background_image)} className="headingImage" /> : null}
        {playlist.name}&nbsp;<small>{playlist.date}</small>
        <Form.Check checked={showVideo} onChange={() => setShowVideo(!showVideo)}
          type="switch"
          label="Show video"
          className="d-xl-block d-none"
        />
      </div>
      <div className="d-flex h-100 justify-content-center">
        {showVideo && selectedVideo ? <PresentationVideo follow video={selectedVideo} votes={currentVotes} /> : null}

        {voteKey === 'results' ? <div>
          <p>Thank you for participating in the HackWeek viewing party!</p>
          <img src={dancingPanda} />
        </div> : <div className={`votingWrapper ${showVideo && selectedVideo ? 'small' : null}`}>
          <h3>{prompt}</h3>
          {options.map((opt) => {
            const selected = (myVotes[voteKey] || []).some(v => v.response_id === opt.id)
            const Check = selected ? CheckCircleFill : CheckCircle
            return <button className={`votingOption ${selected ? 'selected' :''}`} onClick={() => castVote(voteKey, opt.id)} key={opt.id}>
              <Check /> <span className={"ms-2"}>{opt.text}</span>
            </button>
          })}
        </div>}
      </div>
    </div>
  );
}
