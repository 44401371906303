import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import './animate.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Amplify, Auth, DataStore, Hub, Predicates, syncExpression } from "aws-amplify";
import awsconfig from "./aws-exports";
import { Authenticator } from '@aws-amplify/ui-react';
import './dataStoreFilters'

Amplify.configure(Object.assign({}, awsconfig, {
  oauth: {
    domain: 'hackweek-videos.auth.us-west-2.amazoncognito.com',
    scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: window.location.origin + '/',
    redirectSignOut: window.location.origin + '/',
    responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
  }
}));

Hub.listen('auth', (evt) => {
  if(evt.payload.event === 'customOAuthState') {
    window.location = evt.payload.data
  }
})

async function login() {
  let userInfo;
  try {
    userInfo = await Auth.currentUserInfo();
  } catch (ex) {
    console.log(ex)
  }
  if(!userInfo) {
    Auth.federatedSignIn({ customProvider: 'okta', customState: (window.location.pathname + window.location.search + window.location.hash) })
  }
}
login()

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Authenticator.Provider>
  <App />
</Authenticator.Provider>);
