import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function ListGroupItemLink({ to, children }) {
  let navigate = useNavigate();

  return (
    <ListGroup.Item onClick={() => navigate(to)} style={{cursor: 'pointer'}}>
      {children}
    </ListGroup.Item>
  );
}