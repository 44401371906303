import { useAuthenticator } from "@aws-amplify/ui-react";
import { useState, useEffect, useContext, useCallback } from "react";
import { Spinner, Alert } from "react-bootstrap";

export default function({setEmbedInfo, maxLength}) {
  const { user } = useAuthenticator((context) => [context.user]);
  const [ltiConfig, setLtiConfig] = useState(null);
  const [processing, setProcessing] = useState(null);
  const [error, setError] = useState(null);

  const jwt = user.signInUserSession.idToken.jwtToken;

  useEffect(() => {
    if(!user) {
      return;
    }

    async function load() {
      const result = await fetch(`/api/lti/select?id_token=${jwt}`);
      const json = await result.json();
      if(result.status === 200) {
        setLtiConfig(json);
      } else {
        setError("Unable to load video picker");
      }
    }
    load();
  }, [user]);

  useEffect(() => {
    const ltiEventListener = async (event) => {
      if(event.data.messageType != 'lti_media_selection') {
        return;
      }
      if(event.data.data.duration > maxLength) {
        setError("Selected video is too long");
        load();
        return;
      }
      setError(null);
      setProcessing(true);
      const lti_media_id = event.data.data.lti_media_id;
      const result = await fetch(`/api/lti/embed_info?id_token=${jwt}&lti_media_id=${lti_media_id}`);
      const json = await result.json();
      setEmbedInfo(json);
    };

    window.addEventListener("message", ltiEventListener, false);
    return () => {
      window.removeEventListener("message", ltiEventListener);
    }
  }, [maxLength])

  const ltiAutoSubmit = useCallback((form) => {
    form && form.submit()
    setLtiConfig(false);
  }, [maxLength, setEmbedInfo]);

  if(processing) {
    return <><Spinner animation="border" role="status"></Spinner>Loading video data</>
  }
  
  return <div>
    { error ? <Alert variant="danger">{error}</Alert> : null}
    { ltiConfig ? <form action={ltiConfig.launch_url}
      name="ltiLaunchForm"
      id="ltiLaunchForm"
      method="POST"
      target="embedformresponse"
      encType="application/x-www-form-urlencoded"
      ref={ltiAutoSubmit}>
      {Object.entries(ltiConfig.launch_data).map(([key, value]) =>
        <input type="hidden" name={key} value={value} key={key}/>)}
    </form> : null }
    <iframe name='embedformresponse' width='800' height='600' allow="camera *; microphone *; display-capture *"></iframe>
  </div>
}