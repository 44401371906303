import React, { useState } from "react";
import {
  ListGroup,
  Button,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import ListGroupItemLink from "./ListGroupItemLink";
import FileSaver from "file-saver";
import { Link, useNavigate } from 'react-router-dom';
import LoginStatus from "./LoginStatus";
import { APPLAUSE_DURATION, APPROX_VIDEO_START_DELAY } from "./present/PresentationVideo";

// from https://stackoverflow.com/a/7579799
export function seconds2time (seconds) {
  var hours   = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds - (hours * 3600)) / 60);
  var seconds = Math.ceil(seconds - (hours * 3600) - (minutes * 60));
  var time = "";

  if (hours != 0) {
    time = hours+":";
  }
  if (minutes != 0 || time !== "") {
    minutes = (minutes < 10 && time !== "") ? "0"+minutes : String(minutes);
    time += minutes+":";
  }
  if (time === "") {
    time = seconds+"s";
  }
  else {
    time += (seconds < 10) ? "0"+seconds : String(seconds);
  }
  return time;
}


export default function Playlist({playlist})  {
  const navigate = useNavigate();

  function PlayListEntry({entry}) {
    return (
      <ListGroupItemLink
        key={entry.arc_embed_id}
        to={
          "/playlist/" +
          playlist.id +
          "/single/" +
          entry.arc_embed_id
        }>
        <div className="d-flex justify-content-between align-items-center">
          <span>{entry.title}<br/><small>{entry.people.join(", ")}</small></span>
          {!playlist.locked ? <Button size="sm" variant="primary" onClick={(e) => { e.stopPropagation(); navigate(`/playlist/${playlist.id}/edit/${entry.id}`)}}>Edit Submission</Button> : null}
        </div>
      </ListGroupItemLink>
    );
  }

  function handleDownloadResults(e) {
    // TODO reimplement on new arch
    // try {
    //   const fb = firebase.database().ref();

    //   const videos = playlist.videos;
    //   function videoForId(id) {
    //     return videos.find(vid => vid.arc_embed_id === id);
    //   }

    //   const hrefBase = window.location.href;

    //   // This is messy, in that it hard-codes some assumptions, like that there are 4 possible responses.
    //   fb.child("responses")
    //     .child(playlist.polls_id)
    //     .child("questions")
    //     .once("value", ss => {
    //       const responses = ss.val();
    //       let rows = [];

    //       _.each(responses, (deviceResponses, questionId) => {
    //         const video = videoForId(questionId);
    //         if (video) {
    //           const counts = _.reduce(
    //             _.map(deviceResponses.devices, "response"),
    //             (ary, value) => {
    //               if (!value) {
    //                 return ary;
    //               }
    //               return _.map(ary, (v, i) => (value[i] ? v + 1 : v));
    //             },
    //             categories.map(() => 0)
    //           );

    //           rows.push(
    //             [
    //               video.title,
    //               video.people.join(", "),
    //               `${hrefBase}/single/${video.arc_embed_id}`
    //             ].concat(counts)
    //           );
    //         }
    //       });

    //       const data = rows.map(row => row
    //         .map(String)  // convert every value to String
    //         .map(v => v.replaceAll('"', '""'))  // escape double quotes
    //         .map(v => `"${v}"`)  // quote it
    //         .join(',')  // comma-separated
    //       ).join("\r\n")
    //       const blob = new Blob([data], {
    //         type: "application/octet-stream;charset=utf-8"
    //       });
    //       FileSaver.saveAs(blob, "results.csv");
    //     });
    // } catch (e) {
    //   window.alert(
    //     "There was a problem. Perhaps you're not logged into the polling app as the poll owner?"
    //   );
    //   console.log("error downloading poll results", e);
    // }
  }

  function handlePlay () {
    navigate("/playlist/" + playlist.id + "/0");
  }

  function handlePresent () {
    navigate("/playlist/" + playlist.id + "/present");
  }

  const numVideos = playlist.videos.length;
  const numPeople = new Set(playlist.videos.map(v => v.people).flat()).size;
  let duration = playlist.videos.reduce((partialSum, v) => partialSum + v.duration + APPLAUSE_DURATION + APPROX_VIDEO_START_DELAY, 0);

  let bgEl = "";
  if (playlist.background_image) {
    try {
      bgEl = (
        <div
          className="backgroundHolder"
          style={{ backgroundImage: `url("${require('../images/' + playlist.background_image)}")` }}
        />
      );
    } catch (err) {
      console.warn("Could not find image: " + playlist.background_image)
    }
  }

  return (
    <div className="container">
      {bgEl}
      <small><Link to="/">Back to all playlists</Link></small>

      <h1>
        {playlist.name} <small>{playlist.date}</small>
      </h1>

      <div className="d-flex justify-content-between align-items-center mb-2">
        <span>
          {numVideos} videos {duration ? ` (${seconds2time(duration)})` : null}, {numPeople} people
        </span>
        <div className="d-flex">
          <Button variant="secondary" onClick={handlePresent}>
            Present
          </Button>
          &nbsp;
          <Button variant="primary" onClick={handlePlay}>
            &#x25b6; Watch
          </Button>
        </div>
      </div>

      <ListGroup className="mb-2">
        {playlist.videos.sort((a, b) => a.id.localeCompare(b.id)).map((entry) => <PlayListEntry entry={entry} key={entry.id} />)}
      </ListGroup>

      <div className="d-flex justify-content-between">
        {!playlist.locked ? <Button variant="primary" onClick={() => navigate(`/playlist/${playlist.id}/submit`)}>Submit Video</Button> : null }
        <Button variant="light" onClick={() => navigate(`/playlist/${playlist.id}/edit`)}>Edit</Button>
      </div>


      <LoginStatus loggedOutMessage={"Login to submit a video."} />
    </div>
  );
}
