import React, { useState, useEffect } from 'react';
import { Button, ListGroup, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom/';
import ListGroupItemLink from './ListGroupItemLink';
import LoginStatus from './LoginStatus';

import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { Playlist } from '../models';

export default function() {
  const navigate = useNavigate();
  const [playlists, setPlaylists] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const subscription = DataStore.observeQuery(Playlist, Predicates.ALL, {
      sort: s => s.createdAt(SortDirection.DESCENDING)
    }).subscribe(snapshot => {
      const { items, isSynced } = snapshot;
      if(!isSynced) {
        return;
      }
      setLoading(false)
      setPlaylists(items)
    });
    
    return () => {
      subscription.unsubscribe();
    }
  }, []);

  if(loading) {
    return <div>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  }

  return <div className="container">
    <h1>Hack Videos Playlist Viewer</h1>

    <ListGroup className='mb-2'>
      {playlists.map(playlist => 
        <ListGroupItemLink
          key={playlist.id}
          to={"/playlist/" + playlist.id}>
          {playlist.name}<br/>
          <small>{playlist.date}</small>
        </ListGroupItemLink>)}
    </ListGroup>

    <Button variant='secondary' onClick={() => navigate('/playlists/create')}>Add Playlist</Button>

    <hr />

    <LoginStatus loggedOutMessage={"Login for ninja polling."} />
  </div>
}
