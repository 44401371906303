import { withAuthenticator } from "@aws-amplify/ui-react";
import { Button } from "react-bootstrap";
import QRCode from "react-qr-code";

export default function WarmupQuestion({playlist, presentation, votes, startPresentation, url}) {
  const numVideos = playlist.videos.length;
  const numPeople = new Set(playlist.videos.map(v => v.people).flat()).size;
  const totalVotes = votes.length;
  return <div>
    <h1 className="startHeader">
      {playlist.name}&nbsp;<small>{playlist.date}</small>
      <Button className="startButton" onClick={startPresentation}>Start Playlist</Button>
    </h1>
    <h3>
      {numVideos} video{numVideos === 1 ? '' : 's'}, {numPeople} hacker{numPeople === 1 ? '' : 's'}!
    </h3>

    <div style={{background: 'white', padding: '12px', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto'}}>
      <QRCode value={url} />
    </div>

    <hr className="warmupDivider" />
    <h2>{presentation.warmup_question.prompt}</h2>
    <div className="barChart">
      {presentation.warmup_question.options.map((opt) => {
        const voteCount = votes.filter((v) => v.response_id === opt.id).length
        return <div key={opt.id}>
          <div className="bar" style={{width: `${voteCount / totalVotes * 100}%`}}></div>
          <span>{opt.text} ({voteCount})</span>
        </div>
      })}
    </div>
    <h5>{totalVotes} voter{totalVotes === 1 ? '' : 's'}</h5>
  </div>
}
