import { DataStore, syncExpression } from "aws-amplify";
import { useEffect } from "react";
import { Presentation, PresentationVote, PlaylistSubmission } from "./models";


const activePresentationIds = {dummy: 1};
const activePresentationSlugs = {};
const activePlaylistIds = {dummy: 1};

// DataStore.configure({
//   syncExpressions: [
//     syncExpression(PlaylistSubmission, () => {
//       return s => {
//         return s.or(s => Object.keys(activePlaylistIds).map((ap) => s.playlist_id.eq(ap)))
//       }
//     }),
//     syncExpression(Presentation, () => {
//       return p => {
//         return p.or(p =>
//           Object.keys(activePresentationSlugs).map((ap) => p.slug.eq(ap)).concat(
//             Object.keys(activePresentationIds).map((ap) => p.id.eq(ap))
//           )
//         )
//       }
//     }),
//     syncExpression(PresentationVote, () => {
//       return vote => {
//         return vote.or(vote => Object.keys(activePresentationIds).map((ap) => vote.presentation_id.eq(ap)))
//       }
//     })
//   ]
// });

export function usePresentationId(id) {
  countingActiveIdsHook(activePresentationIds, id)
}

export function usePresentationSlug(id) {
  countingActiveIdsHook(activePresentationSlugs, id)
}

export function usePlaylistId(id) {
  countingActiveIdsHook(activePlaylistIds, id)
}

function countingActiveIdsHook(object, id) {
  useEffect(() => {
    if(!id) {
      return;
    }
    object[id] ||= 0;
    object[id] += 1;
    configureDataSync();
    () => {
      object[id] -= 1;
      if(!object[id]) {
        delete object[id]
      }
      configureDataSync();
    }
  }, [id])

}

async function configureDataSync() {
  // await DataStore.stop();
  // await DataStore.start();
}
