import React, { useCallback, useEffect, useState, useRef } from "react";
import { seconds2time } from "../Playlist";

export const APPLAUSE_DURATION = 10;
export const APPROX_VIDEO_START_DELAY = 5.5;

const animations = [
  {
    holderClass: "animate__animated animate__fadeIn",
    titleClass: "hidden",
    peopleClass: "hidden",
    duration: 1000
  },
  {
    titleClass: "animate__animated animate__bounceInLeft",
    peopleClass: "animate__animated animate__bounceInRight",
    duration: 3000
  },
  {
    titleClass: "animate__animated animate__bounceOutRight",
    peopleClass: "animate__animated animate__bounceOutLeft",
    duration: 1000
  },
  {
    titleClass: "hidden",
    peopleClass: "hidden",
    duration: 500,
    actions: ["updatePollQuestion"]
  },
  {
    holderClass: "animate__animated animate__fadeOut",
    titleClass: "hidden",
    peopleClass: "hidden",
    duration: 750
  },
  {
    holderClass: "hidden",
    duration: 1000
  }
];

export default function PresentationVideo({video, remainingVideos, videoCount, votes, advanceVideo, setPollingVideo, follow, slackChannel, playlist}) {
  const [done, setDone] = useState(false);
  const [animationStep, setAnimationStep] = useState(0);
  const animationTimer = useRef();
  const setPollingVideoRef = useRef(setPollingVideo);

  const receiveMessage = useCallback((evt) => {
    if (evt.data === "mediaEnded") {
      setDone(true)
    }
  })

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
    return () => {
      window.removeEventListener("message", receiveMessage);
      if(animationTimer.current) {
        clearTimeout(animationTimer.current);
      }
    }
  }, []);

  useEffect(() => {
    setDone(false)
    animationTick(0);
    notifySlack();
  }, [video.id])

  useEffect(() => {
    setPollingVideoRef.current = setPollingVideo
  }, [setPollingVideo]);

  function animationTick(step) {
    if (step < animations.length) {
      setAnimationStep(step);

      const actions = animations[step].actions || [];
      if (actions.includes("updatePollQuestion") && !follow) {
        setPollingVideoRef.current();
      }

      animationTimer.current = setTimeout(() => {
        animationTimer.current = null;
        animationTick(step + 1);
      }, animations[step].duration);
    }
  }

  function notifySlack() {
    if (!slackChannel) {
      return;
    }

    const location = window.location;
    const url = `${location.origin}/playlist/${playlist.id}/single/${video.arc_embed_id}`;

    const body = {
      text: "",
      attachments: [
        {
          pretext: `*${playlist.name}* _(${playlist.date})_ Hackweek Submission`,
          fallback: video.title,
          title: video.title,
          title_link: url,
          author_name: video.people.join(" ◦ "),
          thumb_url: video.thumbnail
        }
      ],
      channel: slackChannel
    };

    fetch("/api/slack", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(body)
    });
  }

  useEffect(() => {
    if(done) {
      setTimeout(() => {
        advanceVideo()
      }, APPLAUSE_DURATION * 1000);
    }
  }, [done]);

  if (done) {
    const remainingDuration = remainingVideos ? remainingVideos.reduce((partialSum, v) => partialSum + v.duration + APPLAUSE_DURATION + APPROX_VIDEO_START_DELAY, 0) : null;

    const responseCount = new Set(votes.map((v) => v.owner)).size

    return (
      <div className="videoWrapper">
        <div className="applauseHolder animate__animated animate__fadeIn">
          <h1>
            <span className="animate__animated animate__flash animate__infinite animate__fast" role="img" aria-label="clapping">
              👏
            </span>
            <span>
              polite applause
            </span>
            <span className="animate__animated animate__flash animate__infinite animate__fast" role="img" aria-label="clapping">
              👏
            </span>
          </h1>
          <h3 className="responseCount">
            (<b>{responseCount}</b> poll{" "}
            {responseCount === 1 ? "response" : "responses"} so far)
          </h3>
          {remainingVideos ? <span className="doneSummary">
            {videoCount - remainingVideos.length}/{videoCount} ({seconds2time(remainingDuration)} remaining)
          </span> : null}
        </div>
      </div>
    );
  }

  const animation = animations[animationStep];

  const volume = video.volume || "75";
  const studioDomain = video.studio_domain ? video.studio_domain : "hackweek";
  const embedUrl = `https://${studioDomain}.instructuremedia.com/embed/${video.arc_embed_id}?autoplay=4&background=black&volume=${volume}`;

  return (
    <div className="videoWrapper">
      <div className={"titleHolder " + animation.holderClass}>
        <div>
          <h1 className={"title " + animation.titleClass}>{video.title}</h1>
          <h2 className={"people " + animation.peopleClass}>
            {video.people.join(" ◦ ")}
          </h2>
        </div>
      </div>
      <div className="iFrameHolder">
        <iframe
          title="Video"
          width="100%"
          height="100%"
          allowFullScreen={true}
          allow="autoplay; fullscreen"
          src={embedUrl}
          frameBorder="0"
        />
      </div>
    </div>
  );
}

