// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Playlist, PlaylistSubmission, Presentation, PresentationVote } = initSchema(schema);

export {
  Playlist,
  PlaylistSubmission,
  Presentation,
  PresentationVote
};