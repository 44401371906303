import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

const animations = [
  {
    holderClass: "animate__animated animate__fadeIn",
    titleClass: "hidden",
    peopleClass: "hidden",
    duration: 1000
  },
  {
    titleClass: "animate__animated animate__bounceInLeft",
    peopleClass: "animate__animated animate__bounceInRight",
    duration: 3000
  },
  {
    titleClass: "animate__animated animate__bounceOutRight",
    peopleClass: "animate__animated animate__bounceOutLeft",
    duration: 1000
  },
  {
    titleClass: "hidden",
    peopleClass: "hidden",
    duration: 500
  },
  {
    holderClass: "animate__animated animate__fadeOut",
    titleClass: "hidden",
    peopleClass: "hidden",
    duration: 750
  },
  {
    holderClass: "hidden",
    duration: 1000
  }
];

export default function Video({index, playlist, video}) {
  const navigate = useNavigate();
  const [done, setDone] = useState(false);
  const [animationStep, setAnimationStep] = useState(0);
  const animationTimer = useRef();

  const receiveMessage = useCallback((evt) => {
    if (evt.data === "mediaEnded") {
      setDone(true)
    }
  })

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
    return () => {
      window.removeEventListener("message", receiveMessage);
      if(animationTimer.current) {
        clearTimeout(animationTimer.current);
      }
    }
  }, []);

  useEffect(() => {
    setDone(false)
    startAnimations();
  }, [index])

  useEffect(() => {
    if(done) {
      if (done && index + 1 >= playlist.videos.length || index < 0) {
        navigate("/playlist/" + playlist.id)
      } else if(done) {
        navigate("/playlist/" + playlist.id + "/" + (index + 1))
      }
    }
  }, [done])

  function animationTick(step) {
    if (step < animations.length) {
      setAnimationStep(step);

      var actions = animations[step].actions || [];

      animationTimer.current = setTimeout(() => {
        animationTimer.current = null;
        animationTick(step + 1);
      }, animations[step].duration);
    }
  }


  function startAnimations() {
    animationTick(0);
  }

  const animation = animations[animationStep];

  const volume = video.volume || "75";
  const studioDomain = video.studio_domain ? video.studio_domain : "hackweek";
  const embedUrl = `https://${studioDomain}.instructuremedia.com/embed/${video.arc_embed_id}?autoplay=4&background=black&volume=${volume}`;

  return (
    <div style={{height: '100vh', width: '100vw'}}>
      <div className="videoWrapper">
        <div className={"titleHolder " + animation.holderClass}>
          <div>
            <h1 className={"title " + animation.titleClass}>{video.title}</h1>
            <h2 className={"people " + animation.peopleClass}>
              {video.people.join(" ◦ ")}
            </h2>
          </div>
        </div>
        <div className="iFrameHolder">
          <iframe
            title="Video"
            width="100%"
            height="100%"
            allowFullScreen={true}
            allow="autoplay; fullscreen"
            src={embedUrl}
            frameBorder="0"
          />
        </div>
      </div>
    </div>
  );
}

