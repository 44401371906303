import { DataStore } from "aws-amplify";
import { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom"
import { PlaylistSubmission } from "../models";
import VideoPicker from "./VideoPicker";

export default function ({playlist, setReload}) {
  const { submissionId } = useParams();
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const submission = playlist.videos.find(v => v.id == submissionId)
  const [editedSubmission, setEditedSubmission] = useState(submission ? Object.assign({}, submission) : {playlist_id: playlist.id});
  useEffect(() => {
    setEditedSubmission(submission ? Object.assign({}, submission) : {playlist_id: playlist.id});
  }, [submission]);

  async function deleteSubmission() {
    if(confirm(`Are you sure you want to delete '${submission.title}'?`)) {
      const original = await DataStore.query(PlaylistSubmission, submission.id)
      await DataStore.delete(original)
      navigate(`/playlist/${playlist.id}`)
    }
  }

  async function submitForm(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(true);
    let res;
    editedSubmission.people = editedSubmission.people.map(s => s.trim());
    if(submission) {
      const original = await DataStore.query(PlaylistSubmission, submission.id)
      res = await DataStore.save(PlaylistSubmission.copyOf(original, updated => Object.assign(updated, editedSubmission)))
    } else {
      res = await DataStore.save(new PlaylistSubmission(editedSubmission))
    }
    navigate(`/playlist/${playlist.id}`);
  }

  return <div className="container">
    <h1>
      {submission ? `Edit submission: ${submission.title}` : 'Add submission'}
    </h1>

    <Form noValidate validated={validated} onSubmit={submitForm}>
      <Form.Group>
        <Form.Label>Title</Form.Label>
        <Form.Control required value={editedSubmission.title || undefined} onChange={(e) => setEditedSubmission(Object.assign({}, editedSubmission, { title: e.target.value }))}  />
      </Form.Group>
      <Form.Group>
        <Form.Label>People</Form.Label>
        <Form.Control required value={editedSubmission.people ? editedSubmission.people.join(",") : undefined} onChange={(e) => setEditedSubmission(Object.assign({}, editedSubmission, { people: e.target.value.split(',') }))}  />
        <Form.Text className="text-muted">
          If multiple, separate names with commas
        </Form.Text>
      </Form.Group>
      <Form.Group>
        <Form.Label>Select Video (max 60 seconds if less than three contributors, 90 seconds with three or more)</Form.Label><br/>
        { editedSubmission.arc_embed_id ? <div>
          <Button variant="secondary" onClick={() => setEditedSubmission((old) => Object.assign({}, old, {arc_embed_id: null}))}>Replace video</Button><br/>
          <iframe
            title="Video"
            width="800px"
            height="600px"
            allowFullScreen={true}
            allow="autoplay; fullscreen"
            src={`https://${editedSubmission.studio_domain || 'hackweek'}.instructuremedia.com/embed/${editedSubmission.arc_embed_id}`}
            frameBorder="0"
          />
          </div> : null }
        { !editedSubmission.arc_embed_id ? <VideoPicker maxLength={editedSubmission.people && editedSubmission.people.length >= 3 ? 91 : 61} setEmbedInfo={(info) => setEditedSubmission((old) => Object.assign({}, old, info))}/> : null}
      </Form.Group>
      <details className="mb-2">
        <summary>Advanced Options</summary>
        <Form.Group>
          <Form.Label>Volume ({editedSubmission.volume || 75})</Form.Label>
          <Form.Control type="range" default="75" min="0" max="100" value={editedSubmission.volume || 75} onChange={(e) => setEditedSubmission(Object.assign({}, editedSubmission, { volume: parseInt(e.target.value) }))}  />
        </Form.Group>
      </details>

      <div className="d-flex justify-content-between">
        <Button variant="primary" type="submit" disabled={!editedSubmission.title || !editedSubmission.people || !editedSubmission.arc_embed_id}>
          Submit
        </Button>
        {submission ? <Button variant="danger" onClick={() => deleteSubmission()}>
          Delete
        </Button> : null}
      </div>
    </Form>
  </div>
}