import React, { useState } from "react";
import {
  ListGroup,
  Button,
  DropdownButton,
  Dropdown,
  Form
} from "react-bootstrap";
import ListGroupItemLink from "./ListGroupItemLink";
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from "../App";
import { APPLAUSE_DURATION, APPROX_VIDEO_START_DELAY } from "./present/PresentationVideo";
import { seconds2time } from "./Playlist";
import { DataStore } from "aws-amplify";
import { Presentation } from "../models";

function newResponse() {
  return {
    id: crypto.randomUUID(),
    text: 'New Response'
  }
}

function ManageOptions({options, setOptions}) {

  return <Form.Group>
    <ul>
      {options.map((opt) => <li key={opt.id}>
          <Form.Control className="m-1" style={{width: '50%', display: 'inline-block'}} value={opt.text} onChange={(e) => setOptions(options.map(o => o.id === opt.id ? { id: o.id, text: e.target.value } : o))}  />
          <Button className="m-1" variant="danger" onClick={(e) => setOptions(options.filter(o => o.id !== opt.id))}>Remove</Button>
        </li>)}
    </ul>
    <Button onClick={() => setOptions([...options, newResponse()])}>Add Option</Button>
  </Form.Group>

}

function randomSlug(len) {
  const charSet = 'abcdefghijklmnopqrstuvwxyz';
  let randomString = '';
  for (let i = 0; i < len; i++) {
      const idx = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(idx,idx+1);
  }
  return randomString;
}


export default function CreatePresentation({playlist})  {
  const navigate = useNavigate();
  const [draft, setDraft] = useState({
    slack_channel_name: null,
    warmup_question: {
      prompt: null,
      options: []
    },
    categories: []
  });

  const numVideos = playlist.videos.length;
  const numPeople = new Set(playlist.videos.map(v => v.people).flat()).size;
  let duration = playlist.videos.reduce((partialSum, v) => partialSum + v.duration + APPLAUSE_DURATION + APPROX_VIDEO_START_DELAY, 0);

  async function submitForm(e) {
    e.preventDefault()

    const presentation = await DataStore.save(new Presentation(Object.assign({}, draft, {
      playlist_id: playlist.id,
      slug: randomSlug(6)
    })))
    navigate(`/playlist/${playlist.id}/present/${presentation.id}`)
  }

  return (
    <div className="container">
      <small><Link to={`/playlist/${playlist.id}`}>Back to playlist</Link></small>

      <h1>
        Present {playlist.name} <small>{playlist.date}</small>
      </h1>

      <div className="d-flex justify-content-between mb-2">
        <span>
          {numVideos} videos {duration ? ` (${seconds2time(duration)})` : null}, {numPeople} people
        </span>
      </div>

      <Form>
      <Form.Group>
        <Form.Label className="h4">Slack Channel</Form.Label>
        <Form.Control value={draft.slack_channel_name || undefined} onChange={(e) => setDraft(Object.assign({}, draft, { slack_channel_name: e.target.value }))}  />
      </Form.Group>

      <Form.Group>
        <Form.Label className="h4">Warmup Question</Form.Label>
        <Form.Control value={draft.warmup_question.prompt || undefined} onChange={(e) => setDraft(Object.assign({}, draft, { warmup_question: Object.assign({}, draft.warmup_question, { prompt: e.target.value }) }))}  />
      </Form.Group>
      <ManageOptions options={draft.warmup_question.options} setOptions={(o) => setDraft(Object.assign({}, draft, { warmup_question: Object.assign({}, draft.warmup_question, { options: o }) }))} />


      <Form.Label className="h4">Categories</Form.Label>
      <ManageOptions options={draft.categories} setOptions={(c) => setDraft(Object.assign({}, draft, { categories: c }))} />

      <div className="d-flex justify-content-between mt-2">
        <Button variant="primary" type="submit" onClick={submitForm} disabled={!draft.warmup_question.prompt || draft.warmup_question.options.length === 0 || draft.categories.length === 0}>
          Submit
        </Button>
      </div>
    </Form>

    </div>
  );
}
